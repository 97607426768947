import React, { Fragment } from "react"

import { Link } from "gatsby"
import Sub from "~layout/sub"
import aside from "~content/products/aside"
import block from "~content/products/block"
import {
  Heading, BlockGrid, BlockContent, BlockImg, BlockTexts, BlockHeading, BlockDescription, ClickToACtion
} from "./styled"

import { HeadingIcon, HeroBanner } from "~styled"

const crumbs = [
  { text: "Products", to: "/products" },
  { text: "CommonAcademy", to: "/products/commonacademy/" },
]

const CommonAcademyPage = () => {
  let content = (
    <Fragment>
      <HeadingIcon>
        <img src={require("~images/products/common-academy.png").default} alt="CommonAcademy" title="CommonAcademy" />
      </HeadingIcon>

      <Heading>LEARN ONLINE, TEACH ONLINE</Heading>
      <br/>
    <p>As an educator, you may be looking for a digital platform to enhance your classroom teaching. Have you found that current learning management systems aren't maximizing your students' learning while they're online? Many platforms lack some features needed to support certain type of content delivery, differentiated instructions and  adaptive assessment, while others may not be pedagogically effective or offer sufficient reporting. But fear not! We've collaborated with master educators over many years to develop CommandAcademy, the perfect solution for your needs.
</p>
      <h5>
        Designing Courses
      </h5>
      <p>
      CommonAcademy is the ultimate tool for creating multi-level course units with ease. With its intuitive interface, you can quickly create course components and input content include text, images and video. You can also quickly reorder and move subunits around using drag-and-drop in a course map, saving you valuable time and effort.
      </p>
      <p>Our vibrant graphic interface provides a clear and enjoyable user experience, making it easier for you to create and manage your courses. Learners can also benefit from the speedy interface, allowing them to navigate quickly between different units of the course.</p>
      <p>What's more, our course designer allows you to switch between design mode and learner's view with ease. This feature enables you to preview your course as your learners will see it, ensuring that it meets their needs and expectations.</p>
      <p>It takes your course creation to the next level.</p>
      <p>
      <img src={require("~images/products/QCA/Features/image039.jpg").default} alt="CommonAcademy - Designing Courses" title="CommonAcademy - Designing Courses" className="margin-auto img-responsive"/>
      </p>

      <br/>
      <h5>
      Rich Learning Activities
      </h5>
      <p>
      CommonAcademy is designed to elevate learning experience to new heights! With a wide array of learning activities, including text, images, video, and interactive media, our system offers a truly immersive and engaging learning environment.
      </p>
      <p>Our innovative Quiz Question types, such as Multiple Choice, Fill in the Blank, and Question Sets, provide a comprehensive way to assess learners' understanding and retention of the material. Additionally, our Submission Bin feature enables learners to submit their assignments quickly and conveniently, ensuring that their work is collated and organized efficiently.</p>
      <p>To further enhance the learning experience, we also offer a range of Learning Games that are designed to reinforce knowledge and foster a deeper understanding of the material. These games are not only fun and engaging but also offer a powerful way to solidify new concepts and skills.</p>
      <p>CommonAcademy enables course designers to easily create these learning activities in minutes! Don't settle for mediocre learning management system with limited activity or question types.</p>
      <p class="inline-elements flex-fourth">
      <img src={require("~images/products/QCA/Features/image040.jpg").default} alt="CommonAcademy - Rich Learning Activities" title="CommonAcademy - Rich Learning Activities" className="margin-auto img-responsive"/>
      <img src={require("~images/products/QCA/Features/image041.jpg").default} alt="CommonAcademy - Rich Learning Activities" title="CommonAcademy - Rich Learning Activities" className="margin-auto img-responsive"/>
      <img src={require("~images/products/QCA/Features/image042.jpg").default} alt="CommonAcademy - Rich Learning Activities" title="CommonAcademy - Rich Learning Activities" className="margin-auto img-responsive"/>
      <img src={require("~images/products/QCA/Features/image043.jpg").default} alt="CommonAcademy - Rich Learning Activities" title="CommonAcademy - Rich Learning Activities" className="margin-auto img-responsive"/>
      </p>
    <br/>
      <h5>
      Supports for Language Learning
      </h5>
      <p>
      CommonAcademy offers a plethora of learning activities that are specifically designed to cater to the needs of language learners of all levels.
      </p>
      <p>Our state-of-the-art Question Types include Cloze Passage, Text Correction, Email Writing, Audio/Video Response, and Text/Image Matching, to name a few. These activities are carefully curated to provide an immersive and engaging learning experience, enabling learners to practice and reinforce their language skills effectively.</p>
      <p>What's more, we've incorporated speech technologies into our platform to take your learning experience to the next level. With Text-to-Speech and Speech Evaluation (English, Chinese, Arabic), learners can practice and perfect their pronunciation and intonation. And for those who want to revise their vocabulary and have fun while doing it, our innovative Speech Input Games (English, Arabic) provide a fun and interactive way to improve your spoken language abilities.</p>
      <p>Whether you have learners in the beginner or advanced level, CommonAcademy is the perfect tool to help them achieve their language learning goals. With our user-friendly interface and comprehensive features, you'll be able to learn and grow at your own pace, with support and guidance every step of the way.</p>
      <br/>
      <p className="inline-elements flex-double">
      <img src={require("~images/products/QCA/Features/SUPPORTS FOR LANGUAGE LEARNING 1_v2.png").default} alt="CommonAcademy - Supports for Language Learning" title="CommonAcademy - Supports for Language Learning" className="margin-auto img-responsive"/>
      <img src={require("~images/products/QCA/Features/SUPPORTS FOR LANGUAGE LEARNING 2_v2.png").default} alt="CommonAcademy - Supports for Language Learning" title="CommonAcademy - Supports for Language Learning" className="margin-auto img-responsive"/>
      <img src={require("~images/products/QCA/Features/SUPPORTS FOR LANGUAGE LEARNING 3_v2.png").default} alt="CommonAcademy - Supports for Language Learning" title="CommonAcademy - Supports for Language Learning" className="margin-auto img-responsive"/>
      <img src={require("~images/products/QCA/Features/SUPPORTS FOR LANGUAGE LEARNING 4_v2.png").default} alt="CommonAcademy - Supports for Language Learning" title="CommonAcademy - Supports for Language Learning" className="margin-auto img-responsive"/>
      </p>
      <br/>

      <h5>
      Collaborative Learning Activities
      </h5>
      <p>
      We listen to educators who tell us that learning is a collaborative and dynamic process that should be engaging, immersive, and interactive. That's why CommonAcademy offers a range of online real-time collaborative activities that empower teachers and learners to connect, share, and co-create ideas in a seamless and intuitive way.
      </p>
      <p>Our Collaborative Mindmap feature allows for brainstorming, organization, and visualization of ideas, while the Collaborative Canvas allows for the sharing of text, images, audio, and video content, enabling learners to express themselves as post-it notes making sharing fun and dynamic. Moreover, our discussion forum facilitates threaded discussion in groups, making it an effective tool for clarifying new knowledge and exchanging ideas. With CommonAcademy, learners have access to an ecosystem of tools that are designed to foster collaboration, creativity, and critical thinking, making learning a truly transformative experience.</p>
      <br/>
      <p>
      <img src={require("~images/products/QCA/Features/Collaborative Canvas_white_bg_v2.png").default} alt="CommonAcademy - Collaborative Learning Activities" title="CommonAcademy - Collaborative Learning Activities" className="margin-auto img-responsive"/>
      </p>
      <br/>

      <h5>
      Learning Flow Engine
      </h5>
      <p>
      Are you tired of generic and one-size-fits-all learning experiences? Look no further than CommonAcademy's Learning Flow Engine, the ultimate tool for adaptive and personalized learning!
      </p>
      <p>Our Learning Flow Engine works tirelessly behind the scenes, delivering content that is tailored to the individual needs of each learner. Using a graphical user interface (GUI), course designers can define learning flows for learners of different abilities, setting up conditional branching rules that ensure learners receive content that is appropriate for their level.</p>
      <p>This adaptive mechanism ensures that learners of all abilities receive differentiated practices that are tailored to their individual learning goals. With quiz performance and number of tries taken into consideration, learners can receive content that is appropriate for their needs, helping them to achieve their learning goals more effectively.</p>
      <p>Instructors can use our Learning Flow reports to track the progress of their learners, viewing their growth through their individual learning paths. The reports also highlight areas where learners need additional intervention, enabling instructors to provide targeted support and guidance where it is most needed.</p>
      <p>
      <img src={require("~images/products/QCA/Features/image044.jpg").default} alt="CommonAcademy - Learning Flow Engine" title="CommonAcademy - Learning Flow Engine" className="margin-auto img-responsive"/>
      </p>

      <h5>
      Deliberate Practice
      </h5>
      <p>
      Looking for a powerful and effective way to let your learners practice their skills and knowledge? Look no further than CommonAcademy's Deliberate Practice application! This innovative tool uses Item Response Theory (IRT) algorithms to compute learners' ability in real-time as they attempt quiz questions, providing a personalized and adaptive learning experience.
      </p>
      <p>With bite-size worksheets that adapt to learners' current abilities, CommonAcademy's Deliberate Practice application is designed to be convenient and easy to use, whether on desktop computers or mobile phones. Learners can practice anytime, anywhere, making it a flexible and adaptable solution that fits seamlessly into their busy lives.</p>
      <p>And with our comprehensive reports, both learners and teachers can track progress easily, seeing at a glance where they stand in various topics and subtopics for their subject matter. This feedback enables learners to identify areas where they need additional support and guidance, empowering them to take charge of their learning and achieve their goals.</p>
      <p>The Deliberate Practice application also factors in the natural tendency to forget over time. It reintroduces older topics and items, allowing learners to revise and practice them if necessary.
</p>
<p>By doing so, Deliberate Practice helps learners optimize their efforts and use their time effectively, ensuring comprehensive revision coverage.</p>
      <p className="inline-elements flex-double">
      <img src={require("~images/products/QCA/Features/image045.gif").default} alt="CommonAcademy - Deliberate Practice" title="CommonAcademy - Deliberate Practice" className="margin-auto img-responsive"/>
      <img src={require("~images/products/QCA/Features/image046.gif").default} alt="CommonAcademy - Deliberate Practice" title="CommonAcademy - Deliberate Practice" className="margin-auto img-responsive"/>
      <img src={require("~images/products/QCA/Features/image047.gif").default} alt="CommonAcademy - Deliberate Practice" title="CommonAcademy - Deliberate Practice" className="margin-auto img-responsive"/>
      </p>

      {/* <HeroBanner>
        <img src={require("~images/Tech-and-GO.jpg").default} alt="PSG Approved" title="PSG Approved" />
      </HeroBanner>

      <p>A Learning Management System which are used in all Singapore public schools. It has advanced pedagogy and online activities for collaborative learning. Both instructors and student learners can co-create learning possibilities in a secured environment.</p>
      <p>CommonAcademy Learning Management System is a digital learning platform for both instructors and student learners to co-create learning possibilities in a secured environment.</p> */}

      {/* <BlockGrid>
        {block.map(b => {
          const { key, img, title, description } = b;

          return (
            <BlockContent key={key} >
              <BlockImg style={{ backgroundImage: `url('${img.default}')` }} />
              <BlockTexts>
                <BlockHeading>{title}</BlockHeading>
                <BlockDescription>{description}</BlockDescription>
              </BlockTexts>
            </BlockContent>
          )
        })}
      </BlockGrid> */}

      {/* <p>With CommonAcademy, you can save costs by conducting your language lessons online without compromising on quality. It is SaaS-ready, can be hosted in a scalable cloud or an in-house data centre.</p>
      <ClickToACtion>
        <Link to="/contact-us" className="btn btn-primary default-edge">
          Contact Us
        </Link>
      </ClickToACtion> */}

      <br/>
      <h5 className="contactus-info">Contact Us</h5>
      <p>For more information or sales enquiries, email us at <a href="mailto:biz@commontown.com" alt="" >biz@commontown.com</a> or call us at <a href="tel:6848-8900" alt="">6848-8900</a>.</p>
    </Fragment>
  )
  return (
    <Sub
      title="CommonAcademy"
      crumbs={crumbs}
      content={content}
      aside={aside}
    />
  )
}

export default CommonAcademyPage
